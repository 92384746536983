import Vue from "vue";
import Vuetify, {
  VApp,
  VRow,
  VCol,
  VImg,
  VList,
  VListGroup,
  VIcon,
  VBtn,
  VTextField,
  VAlert
} from "vuetify/lib";

import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VApp,
    VRow,
    VCol,
    VImg,
    VList,
    VListGroup,
    VIcon,
    VBtn,
    VTextField,
    VAlert
  },
  directives: {
    Ripple
  }
});

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#fff"
      },
      light: {
        primary: "#1976d2",
        secondary: "#2383bf",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        dark: "#1e1e1e"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
