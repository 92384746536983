<template>
  <component
    :is="mainLayoutLoader"
    v-bind="propsDown"
    @selectedTalhoes="setTalhoes"
    @closeComponent="close"
  ></component>
</template>

<script>
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { globalState } from "./observables/global";
import {
  getters as gettersStateControls,
  mutations as mutationsStateControls
} from "./observables/stateControls";
import { componentMode } from "./const/consts";

Vue.config.productionTip = false;

export default {
  name: "App",
  vuetify,
  i18n,
  data() {
    return {
      loaded: false
    };
  },
  props: {
    token: {
      type: String,
      default: () => ""
    },
    cdUnidade: {
      type: Number,
      required: true
    },
    cdCliente: {
      type: Number,
      required: true
    },
    i18: {
      type: String,
      default: () => "en-us"
    },
    talhoes: {
      type: Array,
      default: () => []
    },
    urlSgp: {
      type: String,
      default: () => ""
    },
    bboxMap: {
      type: Array,
      default: () => []
    },
    selecionaUm: {
      type: String,
      default: () => "T"
    },
    talhoesDestacados: {
      type: String,
      default: () => ""
    },
    modo: {
      type: String,
      default: () => componentMode.PADRAO
    },
    mapFieldFillSetting: {
      type: Object,
      default: () => null
    },
    mapSelectedFieldFillSetting: {
      type: Object,
      default: () => null
    },
    /* Defini qual grupo de servidor este cliente pertence 
    0 - padrao
    1 - estados unidos
    2 - cana
    3 - graos / perenes / outros
    */
    grupo: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    ...gettersStateControls,
    mainLayoutLoader() {
      if (this.loaded) {
        return () => import("./components/Main");
      }
      return null;
    },
    propsDown() {
      return Object.assign({}, this.$props);
    }
  },
  created() {
    this.loaded = true;
  },
  activated() {
    this.loaded = true;
  },
  deactivated() {
    this.loaded = false;
  },
  methods: {
    ...mutationsStateControls,
    setTalhoes() {
      this.$emit("setSelectedTalhoes", globalState.selectedTalhoes);
    },
    close() {
      this.setEditDeleteMode(false);
      this.$emit("close");
    }
  }
};
</script>

<style>
.v-application--wrap {
  min-height: 500px !important;
}
</style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css");
@import url("https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css");
// mapbox
@import url("https://api.mapbox.com/mapbox-gl-js/v1.4.0/mapbox-gl.css");
@import "assets/styles.scss";

.v-application .primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.v-application .primary--text {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}
.v-application .secondary {
  background-color: #424242 !important;
  border-color: #424242 !important;
}
.v-application .info--text {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.v-application .success {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
.v-application .warning {
  background-color: #ffeb3b !important;
  border-color: #ffeb3b !important;
}
.v-application .error {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}
</style>
