import Vue from "vue";

export const stateControls = Vue.observable({
  editDeleteMode: false,
  startAction: false
});

export const getters = {
  getEditDeleteMode: () => stateControls.editDeleteMode,
  getStartAction: () => stateControls.startAction
};

export const mutations = {
  setEditDeleteMode: val => {
    stateControls.editDeleteMode = val;
  },
  setStartAction: val => {
    stateControls.startAction = val;
  }
};
