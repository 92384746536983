import Vue from "vue";
import axios from "axios";
import { formatterArray } from "../helpers/arrayHelpers";

/**
 * Definições globais que são passadas pelos repositórios do sistema onde esse web component é utilizado.
 */
export const globalState = Vue.observable({
  token: "",
  cdUnidade: 0,
  cdCliente: 0,
  grupo: 0,
  i18: "en-us",
  selectedTalhoes: [],
  urlSgp: "",
  bboxMap: [],
  selecionaUm: "T",
  urlServiceGo: "",
  urlTileServiceGo: "",
  axiosServiceGo: null,
  modo: "",
  mapFieldFillSetting: null,
  mapSelectedFieldFillSetting: null
});

export const getters = {
  getToken: () => globalState.token,
  getCdUnidade: () => globalState.cdUnidade,
  getCdCliente: () => globalState.cdCliente,
  getGrupo: () => globalState.grupo,
  getI18n: () => globalState.i18,
  getSelectedTalhoes: () => globalState.selectedTalhoes,
  getUrlSgp: () => globalState.urlSgp,
  getBboxMap: () => globalState.bboxMap,
  getSelecionaUm: () => globalState.selecionaUm,
  getUrlServiceGo: () => globalState.urlServiceGo,
  getUrlTileServiceGo: () => globalState.urlTileServiceGo,
  getModo: () => globalState.modo,
  getAxiosServiceGo: () => globalState.axiosServiceGo,
  getMapFieldFillSetting: () => globalState.mapFieldFillSetting,
  getMapSelectedFieldFillSetting: () => globalState.mapSelectedFieldFillSetting
};

export const mutations = {
  setToken: val => {
    globalState.token = val;
  },
  setCdUnidade: val => {
    globalState.cdUnidade = val;
  },
  setCdCliente: val => {
    globalState.cdCliente = val;
  },
  setGrupo: val => {
    globalState.grupo = val;
  },
  setI18n: val => {
    globalState.i18 = val;
  },
  setSelectedTalhoes: val => {
    globalState.selectedTalhoes = val;
  },
  setUrlSgp: val => {
    globalState.urlSgp = val;
  },
  setBboxMap: val => {
    globalState.bboxMap = val;
  },
  setSelecionaUm: val => {
    globalState.selecionaUm = val;
  },
  setUrlServiceGo: val => {
    globalState.urlServiceGo = val;
  },
  setUrlTileServiceGo: val => {
    globalState.urlTileServiceGo = val;
  },
  setModo: val => {
    globalState.modo = val;
  },
  setAxiosServiceGo: () => {
    globalState.axiosServiceGo = axios.create({
      baseURL: globalState.urlServiceGo,
      headers: {
        "X-Auth-Token": getters.getToken(),
        "Content-type": "application/json"
      },
      timeout: 1000000
    });
  },
  setMapFieldFillSetting: val => {
    globalState.mapFieldFillSetting = val;
  },
  setMapSelectedFieldFillSetting: val => {
    globalState.mapSelectedFieldFillSetting = val;
  }
};

export const actions = {
  fetchListParametrosGerais(cdCliente) {
    return globalState.axiosServiceGo
      .get(`/v1/parametros-gerais/${cdCliente}`)
      .then(result => result.data);
  },
  fetchUnidadeExtent(cdCliente, unidade) {
    return globalState.axiosServiceGo
      .get(`/v1/extent/unidade/${cdCliente}/${unidade}`)
      .then(result => result.data);
  },
  fetchDescTalhaoSource(cdCliente, unidade) {
    return globalState.axiosServiceGo
      .get(`/v1/desc_talhao/unidade/${cdCliente}/${unidade}`)
      .then(result => result.data);
  },
  fetchBuscaTalhoes(cdCliente, cdUnidade, talhoes) {
    return globalState.axiosServiceGo
      .get(
        `/v1/list-fields/${cdCliente}?u=${cdUnidade}${formatterArray(talhoes)}
          `
      )
      .then(result => result.data || []);
  }
};

/* TODO: Remover essas exportações e criar um objeto mutations, como se fosse vuex */
export const setToken = token => {
  globalState.token = token;
};
export const setI18 = i18 => {
  globalState.i18 = i18;
};
export const setUnidade = unidade => {
  globalState.cdUnidade = unidade;
};
export const setCliente = cliente => {
  globalState.cdCliente = cliente;
};
export const setSelectedTalhoes = selectedTalhoes => {
  globalState.selectedTalhoes = selectedTalhoes;
};
export const setUrlSgp = urlSgp => {
  globalState.urlSgp = urlSgp;
};
export const setGrupo = grupo => {
  globalState.grupo = grupo;
};
export const setBboxMap = bboxMap => {
  globalState.bboxMap = bboxMap;
};
export const setSelecionaUm = selecionaUm => {
  globalState.selecionaUm = selecionaUm;
};
export const setModo = modo => {
  globalState.modo = modo;
};
export const setMapFieldFillSetting = mapFieldFillSetting => {
  globalState.mapFieldFillSetting = mapFieldFillSetting;
};
export const setMapSelectedFieldFillSetting = mapSelectedFieldFillSetting => {
  globalState.mapSelectedFieldFillSetting = mapSelectedFieldFillSetting;
};
