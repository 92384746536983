function colorModeFactory(fill, outline, opacity) {
  return {
    fill,
    outline,
    opacity
  };
}

export const componentMode = {
  PADRAO: "PADRAO",
  EDICAO_AREA_TALHAO: "EDICAO_AREA_TALHAO",
  SELECAO_AREA_TALHAO: "SELECAO_AREA_TALHAO"
};

export const colorModes = {
  SATELLITE: {
    base: colorModeFactory("#f2994a", "#d15c3c", 0.7),
    selected: colorModeFactory("#4a5ef2", "#4A5899", 0.65)
  },
  NORMAL: {
    base: colorModeFactory("rgba(237, 236, 214, 0.4)", "rgba(0, 0, 0, 1)", 0.7),
    selected: colorModeFactory("#f28726", "#ca6102", 0.75)
  }
};

export function updatedFieldLayersColorSettings(fieldLayers, colorMode) {
  fieldLayers.forEach(layer => {
    const { paint } = layer;
    const { base, selected } = colorMode;

    switch (layer.id) {
      case "talhoes":
        paint["fill-color"] = base.fill;
        paint["fill-outline-color"] = base.outline;
        paint["fill-opacity"] = base.opacity;
        break;
      case "talhoesSelect":
        paint["fill-color"] = selected.fill;
        paint["fill-outline-color"] = selected.outline;
        paint["fill-opacity"] = selected.opacity;
        break;
    }
  });
}
